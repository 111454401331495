<template>
    <div
        v-if="batchid"
        class="billing-card__header"
        :class="mode === 'list' ? 'with-index' : ''"
    >
        <span
            v-if="mode === 'list'"
            class="billing-card__header_index"
        >
            {{ index + 1 }}.
        </span>
        <span class="billing-card__header_title">{{ title }}</span>
        <span class="billing-card__header_from">
            {{ created_at | moment_from }} ago
        </span>
        <span
            v-show="status !== 'UNPAID'"
            class="billing-card__header_paymethod"
        >
            <BillingMethod />
            <span>{{ getPaymethod(paymethod) || 'Store credit' }}</span>
        </span>
        <span
            class="card_title__status billing-card__header_status"
            :class="getStatusClassName"
        >
            {{ status }}
        </span>
        <span
            class="card_title__detail-total billing-card__header_total"
        >
            {{ total | money }}
        </span>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import BillingMethod from '../../../icons/BillingMethod'

export default {
    components: {
        BillingMethod
    },
    mixins: [filtersMixin],
    props: {
        index: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        mode: {
            type: String,
            default: 'list'
        },
        batchid: {
            type: String,
            required: true
        },
        created_at: {
            type: String,
            required: true
        },
        total: {
            type: String,
            required: true
        },
        paymethod: {
            type: String
        }
    },
    computed: {
        getStatusClassName() {
            return this.status === 'COMPLETE' || this.status === 'PAID' ? 'card_title__status--success' : 'card_title__status--error'
        },
        paymethods() {
            return {
                cc: 'Credit card',
                paypal: 'PayPal'

            }
        }
    },
    methods: {
        getPaymethod(method) {
            return this.paymethods[method] || method
        }
    }
}
</script>
